<template>
  <LiefengContent>
    <template v-slot:title>服务评估</template>
    <template v-slot:toolsbarRight>
      <Form
        :model="searchForm"
        :label-colon="true"
        style="width: 624px"
        :inline="true"
      >
        <FormItem label="姓名">
          <Input
            :maxlength="20"
            v-model.trim="searchForm.name"
            placeholder="请输入姓名"
            style="width: 150px"
          ></Input>
        </FormItem>
        <FormItem label="手机号">
          <Input
            :maxlength="20"
            v-model.trim="searchForm.mobile"
            placeholder="请输入手机号"
            style="width: 150px"
          ></Input>
        </FormItem>
        <Button
          type="primary"
          icon="ios-search-outline"
          @click="search"
          style="margin-right: 10px"
          >查询</Button
        >
        <Button type="success" @click="reset" icon="ios-refresh">重置</Button>
        <Drawer
          title="查询条件"
          v-model="selectQuery"
          width="320"
          :styles="{
            height: 'calc(100% - 55px)',
            overflow: 'auto',
            paddingBottom: '53px',
            position: 'static',
          }"
        >
          <Form :model="searchForm" :label-colon="true">
            <FormItem label="姓名">
              <Input
                v-model.trim="searchForm.name"
                :maxlength="20"
                placeholder="请输入姓名"
              ></Input>
            </FormItem>
            <FormItem label="手机号">
              <Input
                :maxlength="20"
                v-model.trim="searchForm.mobile"
                placeholder="请输入手机号"
              ></Input>
            </FormItem>
            <FormItem label="档案编号">
              <Input
                :maxlength="20"
                v-model.trim="searchForm.archiveNum"
                placeholder="请输入档案编号"
                style="width: 100%"
              />
            </FormItem>
            <FormItem label="所属社区">
              <Input
                :maxlength="20"
                v-model.trim="searchForm.orgName"
                placeholder="请输入所属社区"
              ></Input>
            </FormItem>
            <FormItem label="分类">
              <Select v-model="searchForm.difficultType">
                <Option
                  v-for="item in classificationList"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</Option
                >
              </Select>
            </FormItem>
            <FormItem label="分级">
              <Select v-model="searchForm.difficultLevel">
                <Option
                  v-for="item in levelList"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</Option
                >
              </Select>
            </FormItem>
            <FormItem label="跟进人">
              <Input
                :maxlength="20"
                v-model.trim="searchForm.staffName"
                placeholder="请输入跟进人"
              ></Input>
            </FormItem>
          </Form>
          <div class="demo-drawer-footer">
            <Button style="margin-right: 8px" @click="selectQuery = false"
              >取消</Button
            >
            <Button type="primary" @click="search">查询</Button>
          </div>
        </Drawer>
      </Form>
      <Button
        type="primary"
        @click="$router.push('/archivesindex')"
        icon="ios-arrow-back"
        >返回</Button
      >
      <Icon
        type="md-more"
        @click="selectQuery = true"
        style="font-size: 24px; line-height: 32px; cursor: pointer"
        title="更多查询条件"
      />
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :total="total"
        :curPage="page"
        @hadlePageSize="hadlePageSize"
        :pagesizeOpts="[20, 30, 50, 100]"
        :loading="loading"
        :fixTable="true"
        :pageSize="pageSize"
      ></LiefengTable>
      <!-- 登记服务/修改 -->
      <LiefengModal
        title="新增服务评估"
        :value="addChangeStatus"
        width="550"
        height="500px"
        @input="addChangeStatusFn"
      >
        <template v-slot:contentarea>
          <Form
            ref="addChangeForm"
            :model="addChangeData"
            :label-colon="true"
            :rules="addChangeValidator"
            class="addForm"
          >
            <FormItem label="评估人" :label-width="85" prop="assessor">
              <Input
                v-model.trim="addChangeData.assessor"
                :maxlength="20"
              ></Input>
            </FormItem>
            <FormItem label="评估时间" :label-width="85" prop="assessDate">
              <DatePicker
                type="date"
                v-model="addChangeData.assessDate"
                placeholder="请选择评估时间"
              ></DatePicker>
            </FormItem>
            <FormItem
              label="服务成效（服务对象问题改善情况，志愿者参与成效，社会资源链接情况等）"
              prop="seviceEffect"
            >
              <Input
                type="textarea"
                style="width: 100%"
                :rows="4"
                show-word-limit
                v-model="addChangeData.seviceEffect"
                :maxlength="400"
                placeholder="请输入服务成效，不超过400字"
              ></Input>
            </FormItem>
          </Form>
        </template>
        <template v-slot:toolsbar>
          <Button
            type="info"
            @click="
              cancelModelTip = true;
              addChangeStatusFn(false);
            "
            style="margin-right: 10px"
            >取消</Button
          >
          <Button type="primary" @click="saveAddChange">保存</Button>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route("/archivesassessment")
import LiefengContent from "@/components/LiefengContent";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
export default {
  data() {
    return {
      selectQuery: false, //搜索抽屉
      searchForm: {},

      // 新增
      infoId: "", //新增服务评估Id
      cancelModelTip: true, //true代表关闭时弹窗提示
      addChangeStatus: false,
      addChangeTitle: "",
      addChangeData: {},
      addChangeValidator: {
        assessor: [
          { required: true, message: "请输入评估人", trigger: "blur" },
        ],
        assessDate: [
          {
            required: true,
            type: "date",
            message: "请选择评估时间",
            trigger: "change",
          },
        ],
        seviceEffect: [
          { required: true, message: "请输入服务成效", trigger: "blur" },
        ],
      },
      //搜索分类
      classificationList: [
        { label: "低保低收入对象", value: "1" },
        { label: "特困人员", value: "2" },
        { label: "留守老年人", value: "3" },
        { label: "独居孤寡老人", value: "4" },
        { label: "困境儿童", value: "5" },
        { label: "贫困重度残疾人", value: "6" },
        { label: "其他", value: "7" },
      ],
      //搜索分级
      levelList: [
        { label: "一级", value: "1" },
        { label: "二级", value: "2" },
        { label: "三级", value: "3" },
        { label: "四级", value: "4" },
      ],
      talbeColumns: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "档案编号",
          key: "archiveNum",
          minWidth: 100,
          align: "center",
        },
        {
          title: "姓名",
          key: "name",
          width: 120,
          align: "center",
        },
        {
          title: "手机号",
          key: "mobile",
          width: 150,
          align: "center",
        },
        {
          title: "所属社区",
          key: "orgName",
          minWidth: 150,
          align: "center",
        },
        {
          title: "分类/人员类别",
          key: "difficultType",
          minWidth: 150,
          align: "center",
        },
        {
          title: "分级",
          key: "difficultLevel",
          width: 120,
          align: "center",
        },
        {
          title: "上次评估日期",
          key: "assessDate",
          width: 180,
          align: "center",
        },
        {
          title: "跟进人",
          key: "staffName",
          width: 120,
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          width: 250,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  style: {
                    marginRight: "10px",
                  },
                  on: {
                    click: () => {
                      this.$router.push({
                        path: "/assessmentRecord",
                        query: {
                          id: params.row.archiveId,
                        },
                      });
                    },
                  },
                },
                "查看服务评估"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  on: {
                    click: () => {
                      this.infoId = params.row.archiveId;
                      this.addRecord();
                    },
                  },
                },
                "新增服务评估"
              ),
            ]);
          },
        },
      ],
      tableData: [],
      total: 0,
      page: 1,
      loading: false,
      pageSize: 20,
    };
  },
  methods: {
    // 新增服务评估
    addRecord() {
      this.$refs.addChangeForm.resetFields();
      this.addChangeData = {
        assessor: parent.vue.loginInfo.userinfo.realName,
        assessDate: new Date(),
      };
      this.addChangeIden = true;
      this.addChangeStatus = true;
    },
    addChangeStatusFn(status) {
      if (!status && this.cancelModelTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
          onOk: () => {
            this.addChangeStatus = status;
          },
        });
      } else {
        this.addChangeStatus = status;
      }
    },
    saveAddChange() {
      this.$refs.addChangeForm.validate((status) => {
        if (status) {
          this.cancelModelTip = false;
          this.$post("/archives/api/pc/serviceAssess/add", {
            ...this.addChangeData,
            archiveId: this.infoId,
            assessDate: this.$core.formatDate(
              new Date(this.addChangeData.assessDate),
              "yyyy-MM-dd"
            ),
            staffName: parent.vue.loginInfo.userinfo.realName,
          })
            .then((res) => {
              if (res.code == 200) {
                this.$Message.success({
                  background: true,
                  content: "保存成功",
                });
                this.addChangeStatus = false;
                this.hadlePageSize({
                  page: this.page,
                  pageSize: this.pageSize,
                });
              } else {
                this.$Message.error({
                  background: true,
                  content: res.desc,
                });
              }
            })
            .catch((err) => {
              this.$Message.error({
                background: true,
                content: "保存失败",
              });
            });
        }
      });
    },
    //重置
    reset() {
      this.searchForm = {};
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize,
      });
    },
    //搜索
    search() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize,
      });
    },
    hadlePageSize(obj) {
      this.loading = true;
      this.$post("/archives/api/pc/serviceAssess/queryByPage", {
        ...this.searchForm,
        page: obj.page,
        pageSize: obj.pageSize,
        oemCode: parent.vue.oemInfo.oemCode,
        operatorId: parent.vue.loginInfo.userinfo.custGlobalId,
        operatorOrgCode: parent.vue.loginInfo.userinfo.orgCode,
      })
        .then((res) => {
          if (res.code == 200 && res.dataList) {
            res.dataList.map((item, index) => {
              if (item.assessDate) {
                item.assessDate = this.$core.formatDate(
                  new Date(item.assessDate),
                  "yyyy-MM-dd"
                );
              }
              switch (item.difficultType) {
                case "1":
                  item.difficultType = "低保低收入对象";
                  break;
                case "2":
                  item.difficultType = "特困人员";
                  break;
                case "3":
                  item.difficultType = "留守老年人";
                  break;
                case "4":
                  item.difficultType = "独居孤寡老人";
                  break;
                case "5":
                  item.difficultType = "困境儿童";
                  break;
                case "6":
                  item.difficultType = "贫困重度残疾人";
                  break;
                default:
                  item.difficultType = "其他";
                  break;
              }
              switch (item.difficultLevel) {
                case "1":
                  item.difficultLevel = "一级";
                  break;
                case "2":
                  item.difficultLevel = "二级";
                  break;
                case "3":
                  item.difficultLevel = "三级";
                  break;
                case "4":
                  item.difficultLevel = "四级";
                  break;
              }
            });
            this.tableData = res.dataList;
            this.pageSize = res.pageSize;
            this.total = res.maxCount;
            this.page = res.currentPage;
            this.loading = false;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize,
    });
  },
  components: {
    LiefengContent,
    LiefengTable,
    LiefengModal,
  },
};
</script>
    
<style scoped lang='less'>
/deep/.ivu-form-item {
  margin-bottom: 0;
  width: 210px;
}
/deep/.addForm {
  .ivu-form-item {
    margin-bottom: 24px;
    width: 100%;
  }
}
/deep/.ivu-modal-close {
  display: none;
}
/deep/.ivu-drawer-body {
  .ivu-form-item {
    width: 100%;
  }
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
</style>